import { Button, Col, Divider, Drawer, Form, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../../providers/ContentContext";
import { postNewNotification } from "../../../helpers/apiHelpers/emailNotificationHelpers";
import { getLocalStoragedata } from "../../../helpers/StorageHelper";
import ClientEmailNotificationAudience from "./ClientEmailNotificationAudience";

const NewClientEmailNotification = ({ open, onClose }) => {
  const userId = getLocalStoragedata("userId");

  let {
    openNotification,
    handleError,
    notificationArray,
    setNotificationArray,
    filterValues,
    setFilterValues,
  } = useContentContext();

  useEffect(() => {
    setNotificationArray([]);
    setFilterValues({
      selectedCountry: 1,
      location1SelectedData: null,
      location2SelectedData: null,
      selectedCategoryData: null,
      selectedJobTypeData: null,
    });
  }, []);

  const [form] = Form.useForm();

  const [openCloseAudienceDrawer, setOpenCloseAudienceDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenCloseAudienceDrawer = () => {
    setOpenCloseAudienceDrawer(false);
  };

  const onFinish = async (e) => {
    setLoading(true);
    try {
      await postNewNotification({
        notification_type_id: e?.type,
        user_type_id: 6,
        country_id: filterValues?.selectedCountry,
        title: e?.title,
        subtitle: "ClientNotification",
        description: e?.descri,
        users: notificationArray,

        state_id: filterValues?.location1SelectedData,
        suburb_id: filterValues?.location2SelectedData,
        job_category_id: filterValues?.selectedCategoryData,
        job_type_id: filterValues?.selectedJobTypeData,
        created_by: userId,
      })
        .then((response) => {
          if (response?.data.success) {
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    onClose();
    setLoading(false);
  };
  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <div className="relative bg-secondaryWhite p-7">
            <div className="w-full flex flex-row justify-between pb-5">
              <div className="text-[16px] font-medium text-primaryLight">
                New Notification
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              className="w-full add-form"
              onFinish={onFinish}
              //   fields={[
              //     { name: ["upload"], value: fileList },
              //     { name: ["topimageupload"], value: topFileList },
              //   ]}
              initialValues={{
                type: 1,
                // popular: checkBoxStatus,
              }}
            >
              <div>
                <Form.Item
                  name="type"
                  className="text-[16px] w-60"
                  label="Type"
                  rules={[{ required: true }]}
                >
                  <Select
                    name="type"
                    placeholder="Notification Type"
                    required
                    className="!bg-secondaryWhite"
                    size="large"
                    //   onChange={(e) => {
                    //     resetSelectoreMode(e);
                    //   }}
                    //   onSelect={(e) => {
                    //     handleSelectorMode(e);
                    //   }}
                    options={[
                      { value: 1, label: "All" },
                      { value: 2, label: "Push Notification" },
                      { value: 3, label: "In-App Notification" },
                    ]}
                  />
                </Form.Item>
              </div>

              <div>
                <Button
                  size="large"
                  className="w-60"
                  onClick={() => {
                    setOpenCloseAudienceDrawer(true);
                  }}
                >
                  ADD AUDIENCE
                </Button>
              </div>

              <Divider />

              <div>
                <Form.Item
                  name="title"
                  className="text-[16px]"
                  label="Title"
                  rules={[{ required: true, message: "Title is Required!" }]}
                >
                  <Input
                    name="title"
                    placeholder="Title"
                    className="!bg-secondaryWhite"
                    size="large"
                    minLength={3}
                    maxLength={50}
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  name="descri"
                  className="text-[16px]"
                  label="Message"
                  rules={[{ required: true, message: "Title is Required!" }]}
                >
                  <TextArea
                    name="descri"
                    placeholder="Type message here"
                    size="large"
                    rows={5}
                    // onChange={(e) => {
                    //   setPromoDiscription(e.target.value);
                    // }}
                  />
                </Form.Item>
              </div>

              {/* button */}
              <div className="w-full pt-12">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="font-medium w-full bg-primaryLigh"
                        disabled={notificationArray?.length === 0 || loading}
                      >
                        <p className="text-center text-secondaryWhite">SEND</p>
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="font-medium w-full "
                      onClick={onClose}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </Drawer>

      {openCloseAudienceDrawer && (
        <ClientEmailNotificationAudience
          open={openCloseAudienceDrawer}
          onClose={handleOpenCloseAudienceDrawer}
        />
      )}
    </>
  );
};

export default NewClientEmailNotification;
