import axios from "axios";
import { config, formconfig, url } from "./header";
import { getLocalStoragedata } from "../StorageHelper";

const userId = getLocalStoragedata("userId");

//user List
export async function getAllNotificationUserList(data) {
  return await axios.post(url + "user/list", data, config);
}

export async function getAllNotificationTableData(data) {
  return await axios.post(url + "notification/email/list", data, config);
}

export async function postNewNotification(data) {
  return await axios.post(url + "email/array", data, config);
}

export async function getAllNotificationMoreDetails(data) {
  return await axios.post(url + "email/data", data, config);
}

export async function getAllNotificationAudiensList(data) {
  return await axios.post(url + "email/user/list", data, config);
}
