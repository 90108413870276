import { Button, Divider, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { getAllNotificationMoreDetails } from "../../../helpers/apiHelpers/notificationHelpers";
import { useContentContext } from "../../../providers/ContentContext";
import ViewEmailClientNotificationAudience from "./ViewEmailClientNotificationAudience";

const ViewClientEmailNotification = ({ open, onClose, notificationID }) => {
  let { openNotification, handleError } = useContentContext();

  const [openCloseAudienceDrawer, setOpenCloseAudienceDrawer] = useState(false);
  const [notificationMoreDetails, setNotificationMoreDetails] = useState();

  const handleOpenCloseAudienceDrawer = () => {
    setOpenCloseAudienceDrawer(false);
  };

  useEffect(() => {
    fetchAllNotificationMoreDetails();
  }, []);

  const fetchAllNotificationMoreDetails = async () => {
    try {
      await getAllNotificationMoreDetails({ notification_id: notificationID })
        .then((response) => {
          if (response?.data.success) {
            setNotificationMoreDetails(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <div className="relative bg-secondaryWhite p-7">
            <div className="w-full flex flex-row justify-between pb-5">
              <div className="text-[16px] font-medium text-primaryLight">
                Notification
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <div>
              <p className="text-base font-normal">Notification Type</p>
              <p className="text-base font-medium">
                {notificationMoreDetails?.notification_type_id === 1
                  ? "Push & In App Notification"
                  : notificationMoreDetails?.notification_type_id === 2
                  ? "Push Notification"
                  : notificationMoreDetails?.notification_type_id === 3
                  ? "In-App Notification"
                  : "-"}
              </p>
            </div>

            <div className="pt-5">
              <p className="text-base font-normal">Audience Criteria</p>
              <div>
                <div className="flex flex-row gap-3">
                  <p className="text-base font-normal w-32">Country</p>
                  <span>:</span>
                  <p className="text-base font-medium">
                    {notificationMoreDetails?.country}
                  </p>
                </div>
              </div>

              <div>
                <div className="flex flex-row gap-3">
                  <p className="text-base font-normal w-32">State </p>
                  <span>:</span>
                  <p className="text-base font-medium">
                    {notificationMoreDetails?.state}
                  </p>
                </div>
              </div>

              <div>
                <div className="flex flex-row gap-3">
                  <p className="text-base font-normal w-32">Suburb</p>
                  <span>:</span>
                  <p className="text-base font-medium">
                    {notificationMoreDetails?.suburb}
                  </p>
                </div>
              </div>

              <div>
                <div className="flex flex-row gap-3">
                  <p className="text-base font-normal w-32">Job category </p>
                  <span>:</span>
                  <p className="text-base font-medium">
                    {notificationMoreDetails?.job_category}
                  </p>
                </div>
              </div>

              <div>
                <div className="flex flex-row gap-3">
                  <p className="text-base font-normal w-32">Job Type </p>
                  <span>:</span>
                  <p className="text-base font-medium">
                    {notificationMoreDetails?.job_type}
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <Button
                size="large"
                className="w-60"
                onClick={() => {
                  setOpenCloseAudienceDrawer(true);
                }}
              >
                VIEW AUDIENCE
              </Button>
            </div>

            <Divider />

            <div>
              <p className="text-base font-normal">Title</p>
              <p className="text-base font-medium">
                {notificationMoreDetails?.title}
              </p>
            </div>

            <div className="pt-5">
              <p className="text-base font-normal">Message</p>
              <p className="text-base font-medium">
                {notificationMoreDetails?.description}
              </p>
            </div>

            <div className="pt-5">
              <p className="text-base font-normal">Created By</p>
              <p className="text-base font-medium">
                {notificationMoreDetails?.created_by}
              </p>
            </div>

            <div className="pt-5">
              <p className="text-base font-normal">Created Date</p>
              <p className="text-base font-medium">
                {notificationMoreDetails?.created_at}
              </p>
            </div>
          </div>
        </div>
      </Drawer>

      {openCloseAudienceDrawer && (
        <ViewEmailClientNotificationAudience
          open={openCloseAudienceDrawer}
          onClose={handleOpenCloseAudienceDrawer}
          notificationID={notificationID}
        />
      )}
    </>
  );
};

export default ViewClientEmailNotification;
